import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/layout";
import { SEO } from "../components/seo";

const ContactUsPage = ({ location }) => {
  const { site } = useStaticQuery(graphql`
    query ContactUsPageQuery {
      site {
        siteMetadata {
          title
          companyNumber
          companyEmail
        }
      }
    }
  `);
  return (
    <Layout location={location} site={site}>
      <SEO title="404: Not found" />
      <h1>Under Construction...</h1>
    </Layout>
  );
};

ContactUsPage.propTypes = {
  location: PropTypes.string.isRequired
};

ContactUsPage.defaultPropTypes = {
  location: {}
};

export default ContactUsPage;
